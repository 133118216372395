
		import Async from "/home/mn/node/slidecontrol/slide-control-pwa/node_modules/preact-cli/node_modules/@preact/async-loader/async-legacy.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!/home/mn/node/slidecontrol/slide-control-pwa/node_modules/babel-loader/lib/index.js??ref--4!/home/mn/node/slidecontrol/slide-control-pwa/src/routes/home/index.js");
				typeof cb === 'function' && cb(result);
			});
		}

		export default Async(load);
	